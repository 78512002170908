<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Create Ad</strong>
            </div>
            <div class="text-muted">Create new search XML ad</div>
          </div>
          <div class="card-body">
            <a-form :form="form" @submit.prevent="handleSubmit">
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Campaign">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Campaign of the ad.</span>
                      </template>
                      <a-select
                        v-decorator="['campaign', { initialValue:initialValue, rules: [{ required: true, message: 'Please select campaign.' }]}]"
                        placeholder="Please select a campaign"
                        :showSearch="true"
                        :filterOption="true"
                        optionFilterProp="children"
                      >
                        <a-select-option v-for="obj in campaign.list.filter(ele => ele.Status === 0 )" :key="obj.Id">
                          [{{obj.Id}}] {{ obj.Name }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Ad's name.</span>
                      </template>
                      <a-input type="text" placeholder="Ad Name" v-decorator="[ 'name', {
                      rules: [
                      { required: true, message: 'Name is required.' },
                      { max: 64, message: 'Maximum 64 characters allowed.'}
                      ]}]"/>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Default CPC">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Default cost per click.</span>
                      </template>
                      <a-input type="number" min="0" step="0.0001" placeholder="Default CPC"
                               v-decorator="[ 'defaultCPC', { initialValue: 0.002, rules: [{validator: defaultCpcValidator}] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar"/>
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="ClicksDailyLimit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of clicks allowed per day. The system will even out distribution of clicks throughout the day, ie. "day shaping". Set to 0 for unlimited clicks.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Clicks Daily Limit"
                               v-decorator="[ 'ClicksDailyLimit', { initialValue: 0 }]"/>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Title">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Title of your ad. Click on the information icon to the right to see the list of macros that can be put inside the text.</span>
                      </template>
                      <a-input placeholder="Ad Title" v-decorator="['title', { initialValue: '{QUERY} HERE',
                    rules: [
                        { required: true, message: 'Title is required.' },
                        { max: 256, message: 'Maximum 256 characters allowed.'}
                        ]}]">
                        <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{'max-width': '200%'}">
                          <template slot="title">
                            <b>{query}</b> - Lowercase query string will be replaced.<br>
                            <b>{Query}</b> - Capitalized query string will be replaced.<br>
                            <b>{QUERY}</b> - Uppercase query string will be replaced.<br>
                            <b>{cc}</b> - Lowercase country code will be replaced.<br>
                            <b>{CC}</b> - Uppercase country code will be replaced.<br>
                            <b>{Country}</b> - Capitalized country name will be replaced.<br>
                          </template>
                          <a-icon type="code"/>
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Description">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Description that appears in your ad. Click on the information icon to the right to see the list of macros that can be put inside the text.</span>
                      </template>
                      <a-input placeholder="Description" v-decorator="['description', { initialValue: 'Are you looking for {Query}?',
                      rules: [
                        { required: true, message: 'Description is required.' },
                        { max: 256, message: 'Maximum 256 characters allowed.'}
                        ]}]">
                        <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{'max-width': '200%'}">
                          <template slot="title">
                            <b>{query}</b> - Lowercase query string will be replaced.<br>
                            <b>{Query}</b> - Capitalized query string will be replaced.<br>
                            <b>{QUERY}</b> - Uppercase query string will be replaced.<br>
                            <b>{cc}</b> - Lowercase country code will be replaced.<br>
                            <b>{CC}</b> - Uppercase country code will be replaced.<br>
                            <b>{Country}</b> - Capitalized country name will be replaced.<br>
                          </template>
                          <a-icon type="code"/>
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Description 2">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Description that appears below first description in your ad. Click on the information icon to the right to see the list of macros that can be put inside the text.</span>
                      </template>
                      <a-input placeholder="Additional Description" v-decorator="['description2', { initialValue: 'Check it now.',
                      rules: [
                        { max: 256, message: 'Maximum 256 characters allowed.'}
                        ]}]">
                        <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{'max-width': '200%'}">
                          <template slot="title">
                            <b>{query}</b> - Lowercase query string will be replaced.<br>
                            <b>{Query}</b> - Capitalized query string will be replaced.<br>
                            <b>{QUERY}</b> - Uppercase query string will be replaced.<br>
                            <b>{cc}</b> - Lowercase country code will be replaced.<br>
                            <b>{CC}</b> - Uppercase country code will be replaced.<br>
                            <b>{Country}</b> - Capitalized country name will be replaced.<br>
                          </template>
                          <a-icon type="code"/>
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad Domain/Brand">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Domain or brand name for your ad.</span>
                      </template>
                      <a-input placeholder="Ad Domain/Brand" v-decorator="['AdDomainBrand', { initialValue: 'megaSearch',
                      rules: [
                        { max: 256, message: 'Maximum 256 characters allowed.'}
                        ]}]">
                      </a-input>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Destination URL">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Destination URL that loads when user clicks your ad. Click on the information icon to the right to see the list of macros that can be put inside the URL.</span>
                      </template>
                      <a-input type="url" placeholder="Destination URL" v-decorator="['destinationURL', {
                        rules: [
                        { required: true, message: 'Destination URL is required.' },
                        { max: 512, message: 'Maximum 512 characters allowed.'}
                        ]}]">
                        <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{'max-width': '200%'}">
                          <template slot="title">
                            <b>{query}</b> - Lowercase query string will be replaced.<br>
                            <b>{Query}</b> - Capitalized query string will be replaced.<br>
                            <b>{QUERY}</b> - Uppercase query string will be replaced.<br>
                            <b>{cc}</b> - Lowercase country code will be replaced.<br>
                            <b>{CC}</b> - Uppercase country code will be replaced.<br>
                            <b>{Country}</b> - Capitalized country name will be replaced.<br>
                          </template>
                          <a-icon type="code"/>
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Remote Channel">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Channel name for ad on the advertiser side.</span>
                      </template>
                      <a-input placeholder="Remote Channel" v-decorator="['RemoteChannel', {
                        rules: [
                        { max: 8, message: 'Maximum 8 characters allowed.'}
                        ]}]">
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>

              <div class="ant-row ant-form-item">
                <a-divider class="mt-3"></a-divider>
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Create Ad</button>
                  <button type="button" @click="$router.push({ name: 'xml-ads' })" class="btn btn-light px-5 ml-2">
                    Cancel
                  </button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { createAd } from '@/api/xml/ad'

export default {
  components: {},
  computed: {
    ...mapState(['campaign']),
    // initialValue() {
    //   if (this.$route.params.campaignId) {
    //     return this.campaign.list.filter(ele => ele.Id === parseInt(this.$route.params.campaignId))[0].Id
    //   } else {
    //     return 'Please select a campaign'
    //   }
    // },
    initialValue() {
      if (this.$route.params.campaignId) {
        const campaign = this.campaign.list.find(ele => ele.Id === parseInt(this.$route.params.campaignId))
        return campaign === undefined ? [] : campaign.Id
      } else {
        return []
      }
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    }
  },
  methods: {
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.destinationURL !== '' && values.destinationURL) {
            values.destinationURL = values.destinationURL.replace(/\s/g, '')
          }
          createAd(values).then(response => {
            if (response.Status === 'Ok') {
              this.$notification.success({
                message: 'Ad Created',
                description: `Ad ${response.Ad.Name} has been successfully created.`,
              })
              // this.$router.push('/xml/ad/update/' + response.Ad.Id)
              this.$router.push({
                name: 'xml-ad-update',
                params: { id: response.Ad.Id },
              }).catch(() => {
              })
            } else if (typeof response.type !== 'undefined' && response.type === 'error') {
              this.$notification.success({
                message: 'Ad Created',
                description: 'Ad has been successfully created.',
              })
            }
          }).catch(err => console.log(err))
          // this.$store.dispatch('ad/CREATE_AD', { payload: values, callback: function() { this.$router.push('/ads') }.bind(this) })
        }
      })
    },
    defaultCpcValidator(rule, value, message) {
      if (value === '') {
        message()
      } else if (isNaN(parseFloat(value))) {
        message('Invalid number.')
      } else if (parseFloat(value) < 0) {
        message('Default CPC can not be negative.')
      }
      message()
    },
  },
  created() {
    this.$store.dispatch('campaign/LOAD_CAMPAIGNS')
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}

@media only screen and (min-width: 768px) {
  .flex {
    display: flex;
    flex-direction: col;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .-m {
    margin-right: -4rem;
  }

  .break-line >>> li .ant-tree-node-content-wrapper {
    margin-bottom: 5px;
    padding-top: 4px;
    display: inline-block !important;
    white-space: pre-wrap !important;
    line-height: 15px !important;
    width: 90% !important;
  }
}
</style>
